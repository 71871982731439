import React from 'react'
import { Box } from 'theme-ui'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'

const style = {
  blogBody: {
    img: { width: `100%` }
  }
}

export const PostBody = ({ bodyContent }) => {
  return (
    <Box
      sx={style.blogBody}
      className='blog-body-content'
      dangerouslySetInnerHTML={{ __html: bodyContent }}
    ></Box>

    // <MDXProvider components={components}>
    //   <MDXRenderer>{body}</MDXRenderer>
    // </MDXProvider>
  )
}
