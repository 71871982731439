import React from 'react'
import { Link as GLink } from 'gatsby'
import { Box, Heading, Card, Link } from 'theme-ui'
import Navigation from '@components/Navigation'
import Section from '@components/Section'
import Avatar from '@components/Avatar'
import attachSocialIcons from '@helpers/attachSocialIcons'
import { allStrapiAuthor } from '../../../../themes/gatsby-theme-flexiblog-personal/src/helpers/nr-authors-des.helper'

const styles = {
  wrapper: {
    textAlign: `center`
  },
  avatarWrapper: {
    mb: 4
  },
  title: {
    color: `omegaDark`
  },
  des: {
    textAlign: 'left',
    lineHeight: '1.5'
  }
}

const AuthorCompact = ({ authors, omitSocial, ...props }) => {
  if (!authors) return ''

  return (
    <Section aside title='The Author' {...props}>
      {authors.map(author => {
        return (
          <>
            <Card variant='paper'>
              <Box sx={styles.wrapper}>
                {author.thumbnail && (
                  <Box sx={styles.avatarWrapper}>
                    <Link as={GLink} to={`/author/${author.slug}/`} aria-label={author.name}>
                      <Avatar
                        avatar={author.thumbnail}
                        alt={author.name}
                        withPattern
                      />
                    </Link>
                  </Box>
                )}
                <Link as={GLink} to={`/author/${author.slug}/`}>
                  <Heading variant='h3'>{author.name}</Heading>
                </Link>
                <Heading variant='h4' sx={styles.title}>
                  {author.title}
                </Heading>
                <Box variant='h4' sx={styles.des}>
                  <div dangerouslySetInnerHTML={{ __html: allStrapiAuthor(author.name) }}></div>
                </Box>
                <br />
                {!omitSocial && author.social && author.social[0].url && (
                  <Navigation
                    variant='horizontal'
                    items={attachSocialIcons(author.social)}
                    iconOnly
                  />
                )}
              </Box>
            </Card>
            <br />
          </>
        )
      })}
    </Section>
  )
}

export default AuthorCompact
