import React from 'react'
import { Layout, Stack, Main, Sidebar, Hero } from '@layout'
import CardList from '@components/CardList'
import Seo from '@widgets/Seo'
import {
  Card as CardComponent,
  Button,
  Heading,
  Box,
  Text,
  Badge
} from 'theme-ui'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import { Link } from 'gatsby'
import { readingTime } from 'reading-time-estimator'
import './Blog.css'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostCommentsFacebook,
  PostCommentsGraph,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'
import OpenMapSource from './Map'
import AuthorCompact from '@widgets/AuthorCompact'
import Categories from '@widgets/Categories'
import { attachCategoriesToBlogs, attachSeoDetails } from '@utils-blog'
import {
  getSelectedBlogAuthors,
  getStrapiBlogFromTitle,
  attachThumbnail
} from '../../../gatsby-blog-helpers/src'
import { useBlogCategories } from '@helpers-blog'
import PageTitle from '@components/PageTitle/PageTitle'
import Section from '@components/Section'

const styles = {
  innerSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  buttons: {
    a: {
      borderRadius: `12px`
    }
  },
  image: image => ({
    display: [`none`, null, `block`],
    height: 305,
    width: `1/4`,
    minWidth: 457,
    maxHeight: 305,
    minHeight: 305,
    borderRadius: `0px 50px 50px 50px`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `center`,
    overflowX: `hidden`,
    zIndex: 2
  }),

  border: {
    display: 'none',
    '@media screen and (min-width: 1024px)':{
      display: 'block'
    },
    '&::before, &::after': {
      content: `""`,
      position: `absolute`
    },
    '::before': {
      bg: `#E4E7EB`,
      top: `-6px`,
      left: `calc(100% - 463px)`,
      width: `175px`,
      height: `133px`,
      zIndex: -1
    },
    '::after': {
      bg: `#DBE0E7`,
      width: `175px`,
      height: `133px`,
      right: `-5px`,
      top: `178px`,
      borderRadius: `0px 18px 50px 18px`,
      zIndex: -1
    }
  },
  mainTitle: {
    fontSize: '3rem',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '900',
    lineHeight: '1.33',
    // maxWidth: '720px',
    marginBottom: '24px'
  },
  date: {
    fontSize: '15px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    marginBottom: '26px'
  },

  eventLink: {
    width: 'fit-content',
    fontSize: '18px',
    fontWeight: '400',
    padding: '10px 20px',
    borderRadius: '12px',
    border: 'none'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '@media screen and (min-width: 1024px)':{
      width: '50%',
    },
  }
}

const BlogDetail = ({
  data: {
    blogDetail,
    eventDetail,
    videoDetail,
    partnerDetail,
    tagCategoryPosts,
    tagPosts,
    categoryPosts,
    previousBlog,
    previousEvent,
    previousPartner,
    nextEvent,
    nextBlog,
    nextPartner
  },
  ...props
}) => {
  const { pageContext: { services = {}, siteUrl } = {} } = props
  let post = blogDetail
    ? blogDetail
    : eventDetail
    ? eventDetail
    : videoDetail
    ? videoDetail
    : partnerDetail
  const { name } = props.pageContext
  let categories
  let seoData = {}
  let previous, next, previousTitle, nextTitle
  let authors
  attachBodyContent(post)
  function formatDate(date) {
    const formattedDate = new Date(date).toDateString(
      {},
      { timeZone: 'UTC', month: 'long', day: '2-digit', year: 'numeric' }
    )
    const sp = formattedDate.split(' ')
    return `${sp[1]}, ${sp[2]} ${new Date().getFullYear()}`
  }

  if (name === 'partnerPage') {
    post = attachSeoDetails(post)

    seoData = {
      description: post?.seo?.metaDescription,
      title: post?.seo?.metaTitle,
      keywords: post?.seo?.keywords?.split('\n'),
      metaImage: {
        url: 'https://newmanreview-assets.s3.ap-south-1.amazonaws.com/syposium_4bf3fb68e1.jpg'
      }
    }
  }

  if (name === 'Event') {
    post = attachSeoDetails(post)
    post.name = name
    seoData = {
      description: post?.seo?.metaDescription,
      title: post?.seo?.metaTitle,
      keywords: post?.seo?.keywords?.split('\n'),
      metaImage: {
        url: 'https://newmanreview-assets.s3.ap-south-1.amazonaws.com/syposium_4bf3fb68e1.jpg'
      }
    }
  }

  if (name === 'videoPage') {
    post = attachSeoDetails(post)
    post.name = name
    seoData = {
      description: post?.seo?.metaDescription,
      title: post?.seo?.metaTitle,
      keywords: post?.seo?.keywords?.split('\n'),
      metaImage: {
        url: 'https://newmanreview-assets.s3.ap-south-1.amazonaws.com/syposium_4bf3fb68e1.jpg'
      }
    }
  }

  if (name === 'blogPage') {
    post.name = name
    if (post.bodyContent) {
      const text = post.bodyContent.replace(/(<([^>]+)>)/gi, '')
      post.readingTime = readingTime(text, 220)
    }
    post = attachSeoDetails(post)
    seoData = {
      description: post?.seo?.metaDescription,
      title: post?.seo?.metaTitle,
      keywords: post?.seo?.keywords?.split('\n'),
      metaImage: {
        url: 'https://newmanreview-assets.s3.ap-south-1.amazonaws.com/syposium_4bf3fb68e1.jpg'
      }
    }
  }

  if (name !== 'partnerPage') {
    authors = getSelectedBlogAuthors(post.title)
    if (authors) {
      attachThumbnail(authors)
    }
    attachCategoriesToBlogs(post)
    categories = useBlogCategories()
    switch (post.categories[0].name) {
      case 'Event': {
        previous = previousEvent
        next = nextEvent
        previousTitle = 'Previous Event'
        nextTitle = 'Next Event'
        break
      }
      case 'Blog': {
        previous = previousBlog
        next = nextBlog
        previousTitle = 'Previous Blog'
        nextTitle = 'Next Blog'
        break
      }
    }
  } else if (name === 'partnerPage') {
    previous = previousPartner
    next = nextPartner
    previousTitle = 'Previous Partner'
    nextTitle = 'Next Partner'
  }
  if (name === 'blogPage') {
  categories=categories.filter(item=>item.type==='Blog')
  }

  function attachBodyContent(post) {
    let article = getStrapiBlogFromTitle(post.title)
    post['bodyContent'] = article.body.data.body
    if (name === 'Event') {
      post['imageUrl'] = article.featuredImage
    }
  }
  if (post.videoLink && post.videoLink.search('watch') > -1) {
    post.videoLink = post.videoLink.replace('watch?v=', 'embed/')
  }
  return (
    <Layout {...props}>
      <Seo
        title={seoData?.title}
        description={seoData?.description}
        keywords={seoData?.keywords}
        thumbnail={seoData?.metaImage}
      />
      <Box style={{ paddingTop: '70px' }}>
        {name !== 'Event' && (
          <Stack effectProps={{ effect: 'fadeInDown' }}>
            <PostHead {...post} name={name} />
          </Stack>
        )}
        {name === 'Event' && (
          <Hero full>
            <Stack>
              <Box sx={styles.wrapper}>
                <Box sx={styles.innerWrapper}>
                <Heading sx={styles.mainTitle} dangerouslySetInnerHTML={{ __html: post.title }} />

                  {name === 'Event' && (
                    <Text variant='small' sx={styles.date}>
                      {formatDate(post.eventdate)} At{' '}
                      {new Date(post.eventdate).toTimeString().slice(0, 5)}{' '}
                      Hours
                    </Text>
                  )}
                  <Box variant='buttons.group' sx={styles.buttons}>
                    <Button as={Link} to={post.link}>
                      Event Link Here
                    </Button>
                  </Box>
                </Box>
                <Box sx={styles.border}>
                  <Box sx={styles.image(post.imageUrl[0].url || {})}></Box>
                </Box>
              </Box>
            </Stack>
          </Hero>
        )}
        {name === 'videoPage' && (
          <Stack>
            <Main>
              <Divider />
              <iframe
                width='100%'
                height='700'
                src={post.videoLink}
                frameborder='0'
                allowfullscreen
              ></iframe>
            </Main>
          </Stack>
        )}
        {name === 'Event' && (
          <Stack
            style={{
              padding: '16px 0px 35px 0px'
            }}
          >
            <Box style={{ margin: '0px -25px' }}>
              <Box
                sx={{
                  fontFamily: 'Inter, sans-serif',
                  lineHeight: '1.5'
                }}
              >
                <Divider />

                <PostBody {...post} />
              </Box>
              <Divider />
              {post.location ? (
                <OpenMapSource coordinates={post.location} />
              ) : null}
            </Box>
          </Stack>
        )}

        {name !== 'Event' && (
          <Stack effectProps={{ fraction: 0 }}>
            <Main>
              <Divider />
              <CardComponent variant='paper'>
                {name !== 'videoPage' && name !== 'Event' && (
                  <PostImage {...post} inCard />
                )}
                <PostBody {...post} />

                {/* <PostTagsShare {...post} location={props.location} /> */}
                {/* {services.disqus && <PostComments {...post} />}
            {services.graphComment && <PostCommentsGraph {...post} />}
            {services.facebookComment && (
              <PostCommentsFacebook {...post} siteUrl={siteUrl} />
            )} */}
                {name !== 'partnerPage' && (
                  <PostFooter
                    {...{ previous, next }}
                    previousTitle={previousTitle}
                    nextTitle={nextTitle}
                    name={name === 'partnerPage' ? 'partners' : ''}
                  />
                )}
              </CardComponent>
            </Main>
            {name === 'blogPage' && (
              <Sidebar>
                <Divider />

                <AuthorCompact authors={authors} omitTitle />
                <Divider />
                <Categories categories={categories} />
                <Divider />
              </Sidebar>
            )}
            {name === 'videoPage' && (
              <Sidebar>
                <Divider />

                <AuthorCompact authors={authors} omitTitle />
              </Sidebar>
            )}
            {name === 'Event' && (
              <Sidebar>
                <Divider />
              </Sidebar>
            )}
          </Stack>
        )}
      </Box>
    </Layout>
  )
}

export default BlogDetail
