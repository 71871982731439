import { useStaticQuery, graphql } from 'gatsby'

// :::: GET Author Description from StrapiAuthors ::::
export const allStrapiAuthor = name => {
  const { allStrapiAuthor } = useStaticQuery(allStrapiAuthorDes)
  const authorDescription = allStrapiAuthor.nodes.filter(
    node => node.name === name
  )

  return authorDescription[0].authorDescription.data.authorDescription
}

const allStrapiAuthorDes = graphql`
  query {
    allStrapiAuthor {
      nodes {
        name
        authorDescription {
          data {
            authorDescription
          }
        }
      }
    }
  }
`
