import { graphql } from 'gatsby'
import Collection from '../containers/Blog'

export default Collection

export const pageQuery = graphql`
  query myQuery(
    $id: String!
    $imageQuality: Int!
    $previousId: String
    $nextId: String
    $includeTimeToRead: Boolean!
  ) {
    blogDetail: blog(id: { eq: $id }) {
      ...BlogInformation
    }

    eventDetail: event(id: { eq: $id }) {
      ...EventDetailInformation
    }
    videoDetail: video(id: { eq: $id }) {
      ...VideoInformation
    }

    partnerDetail: partner(id: { eq: $id }) {
      ...PartnerInformation
    }

    previousVideo: video(id: { eq: $previousId }) {
      id
      slug
      title
    }

    nextVideo: video(id: { eq: $nextId }) {
      id
      slug
      title
    }
    previousBlog: blog(id: { eq: $previousId }) {
      id
      slug
      title
    }

    nextBlog: blog(id: { eq: $nextId }) {
      id
      slug
      title
    }

    previousEvent: event(id: { eq: $previousId }) {
      id
      slug
      title
    }

    nextEvent: event(id: { eq: $nextId }) {
      id
      slug
      title
    }
    previousPartner: partner(id: { eq: $previousId }) {
      id
      slug
      title
    }

    nextPartner: partner(id: { eq: $nextId }) {
      id
      slug
      title
    }
  }
`
